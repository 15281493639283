<template>
    <div>        
        <b-row>
            <b-col md="6" sm="12">
                <b-card>
                    <b-card-text class="font-weight-bold mb-2">
                        Gráfico
                    </b-card-text>
                    
                    <b-row>
                        <b-col sm="12" class="mb-3">
                            <b-col sm="12">
                                <b-row class="text-center">
                                    <b-col sm="4" md="2" class="my-auto">                                    
                                        <feather-icon icon="CalendarIcon" size="1.5x" class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  /> De
                                    </b-col>
                                    <b-col sm="8" md="3" class="my-auto">                                    
                                        <flat-pickr
                                            v-model="startDate"
                                            class="form-control"
                                            :config="configCalendar"
                                        />        
                                    </b-col>
                                    <b-col sm="4" md="1" class="my-auto"> 
                                        a
                                    </b-col>
                                    <b-col sm="8" md="3" class="my-auto">
                                        <flat-pickr
                                            v-model="endDate"
                                            class="form-control"
                                            :config="configCalendar"
                                        />        
                                    </b-col>    
                                    <b-col sm="12" md="3" class="my-auto"> 
                                    <b-button
                                            size="sm"
                                            variant="primary"
                                            @click="filterDate"
                                        >                                    
                                        <feather-icon icon="FilterIcon" size="1x"/>   
                                        Filtrar
                                    </b-button>
                                    </b-col>                            
                                </b-row>                            
                            </b-col>
                        </b-col>
                        
                        <!-- <hr> -->
                        <b-col sm="10" class="mx-auto">
                            <vue-apex-charts
                                ref="chartBar"
                                type="bar"
                                :options="chartOptions"
                                :series="dataSeries"
                            />    
                        </b-col>                        
                    </b-row>                        
                </b-card>
            </b-col>
            <b-col md="6" sm="12">
                <b-row>
                    <b-col sm="12">
                        <statistic-card-horizontal
                            icon="AwardIcon"
                            color="info"
                            :statistic="scoreAVG"
                            statistic-title="Promedio General de las otras Empresas"
                        />
                    </b-col>
                    <b-col sm="12">        
                        <b-card 
                            class="card-transaction"
                            no-body>
                            <b-card-header>
                                <b-card-title>Lista de Formularios</b-card-title>
                            </b-card-header>

                            <b-card-body v-if="formData.length > 0">
                                <div
                                    v-for="(item, index) in formData"
                                    :key="index"
                                    class="transaction-item"
                                >
                                    <b-media no-body>
                                        <b-media-body>
                                            <h6 class="transaction-title">
                                            {{ item.form }}
                                            </h6>
                                            <small><strong>Revisor:</strong> {{ item.reviser }}</small>
                                        </b-media-body>
                                    </b-media>
                                    <div
                                    class="font-weight-bolder"
                                    :class="item.score < scoreAVG ? 'text-danger':'text-success'"
                                    >
                                        {{  item.score }}
                                    </div>
                                </div>
                            </b-card-body>
                            <b-card-body v-else>
                                <b-col>
                                    No se encontraron datos.
                                </b-col>
                            </b-card-body>
                        </b-card>                                
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import axios from '@axios';
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';

export default {
    components: {
        VueApexCharts,
        flatPickr,
        StatisticCardHorizontal,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            startDate: "",
            endDate: "",
            providerId: JSON.parse(localStorage.getItem('userData')).provider,
            rangeDate: null,
            scoreAVG: 0,
            formData: [],            
            colorsArr: [],
            chartOptions: {
                chart: {
                    sparkline: { enabled: false },
                    toolbar: { show: false },
                },
                grid: {
                    show: true,
                    padding: {
                        left: 0,
                        right: 0,
                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'lighten',
                            value: 0.15,
                        }
                    },
                },
                title: { 
                        text: "Nota de Formularios vs Promedio General de otras empresas", 
                        align: "center", 
                        style: { 
                            fontSize: "14px", 
                            fontWeight: "bold", 
                            fontFamily: "Montserrat, Helvetica, Arial, serif", 
                            color: $themeColors.primary, 
                        }
                },
                noData: { text: "Sin datos...", },
                height: "700px",
                plotOptions: {
                    bar: {
                        borderRadius: 15,
                    },
                },
                tooltip: {
                    x: { show: false },
                },
                xaxis: {
                    type: 'numeric',
                },
                 dataLabels: {
                    enabled: false
                },
            },
            dataSeries: [
                {
                    name: "Mis Notas",
                    data: []
                },
                {
                    name: "Nota Promedio",
                    data: [],
                }
            ],
            configCalendar: {
                dateFormat: 'd-m-Y',
                locale:  SpanishLocale,
            },
        }       
    },
    beforeMount() {
        this.getInformation();
    },
    methods: {
        getInformation(){
            axios
            .get('/provider/form/all')
            .then(response => {
                if(response.data){
                    let total = 0, cont = 0, avg = 0, i = 0; 
                    let providerForm = [], scoresArr = [];
                    
                    response.data.map( item => {                   
                        if(item.state_id == 1 && item.provider_id != this.providerId){ //SOLO CUENTA LOS FORMULARIOS COMPLETADOS SIN CONSIDERAR AL MISMO PROVEEDOR                            
                            total += parseFloat(item.score); cont++;
                        }                 
                        if(item.provider_id == this.providerId){
                            providerForm.push({
                                form: "N° " + (i+1),
                                reviser: item.reviser.user.names+' '+item.reviser.user.surnames,
                                score: item.score,
                            });
                            scoresArr.push(item.score);
                            i++;
                        }
                    });
                    
                    this.formData = providerForm;                    
                    avg = cont > 0 ?  total/ cont : 0;
                    this.scoreAVG = avg.toFixed(2);   
                    this.dataSeries[0].data = scoresArr;                    
                    this.dataSeries[1].data.push(this.scoreAVG);    
                }
            });
        },
        filterDate(){
            axios
            .get(`/provider/form/all?filterStartDate=${this.startDate}&filterEndDate=${this.endDate}`)
            .then(response => {
                if(response.data){
                    let total = 0, cont = 0, avg = 0, i = 0; 
                    let providerForm = [], scoresArr = [];
                    
                    response.data.map( item => {         
                        if(item.state_id == 1 && item.provider_id != this.providerId){ //SOLO CUENTA LOS FORMULARIOS COMPLETADOS SIN CONSIDERAR AL MISMO PROVEEDOR                            
                            total += parseFloat(item.score); cont++;
                        }                 
                        if(item.provider_id == this.providerId){
                            providerForm.push({
                                form: "N° " + (i+1),
                                reviser: item.reviser.user.names+' '+item.reviser.user.surnames,
                                score: item.score,
                            });
                            scoresArr.push(item.score)
                            i++;                            
                        }
                    });
                    
                    this.formData = providerForm;                    
                    avg = cont != 0 ? total/ cont : 0;
                    this.scoreAVG = avg.toFixed(2);   
                    
                    this.dataSeries = [{
                        data: scoresArr, 
                    },{
                        data: [this.scoreAVG]
                     }];
                }                
            });
        },
    }
}
</script>

<style scoped>
    input[type=text] {
            border: none;
            border-radius: 0px;
            border-bottom: 1px solid #d8d6de;
        }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
